$transition-fast-in-slow-out: cubic-bezier(0.1, 0.82, 0.25, 1);//cubic-bezier(0.1, 0.82, 0.25, 1);
$transition-slow-in-fast-out: cubic-bezier(0.550, 0.055, 0.675, 0.190);

$mobile-width: "only screen and (max-width : 450px)";
$tablet-width: "only screen and (max-width : 650px)";
$desktop-width: "only screen and (max-width : 1024px)";

@mixin on_click_button {
  cursor: pointer;
  transition: transform 500ms cubic-bezier(0.1, 0.82, 0.25, 1);

  &:hover {
    transform: scale(1.1);
    opacity: 0.9;
  }
}


@mixin small {
  @media #{$mobile-width}{
    @content;
  }
}

@mixin medium {
  @media #{$tablet-width}{
    @content;
  }
}

@mixin large {
  @media #{$desktop-width}{
    @content;
  }
}
