@import '~Styles/mixins.module';


.background_image_layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
}


@include medium {
  .background_image_layer {
    width: 120%;
  }
}